import persist from 'pinia-plugin-persistedstate';

// 创建 pinia 实例
const pinia = createPinia();
// 使用持久化存储插件
pinia.use(persist);

// 默认导出，给 main.ts 使用
export default pinia;

export * from './modules/station';
