<template>
  <div>
    <a-form ref="formRef" :model="formState" :rules="rules" v-bind="layout">
      <a-divider></a-divider>
      <a-form-item v-for="(item, index) in formItemList" :key="index" has-feedback :label="item.label" :name="item.name" class="mb-2 mt-4">
        <!-- 图片上传 -->
        <a-upload
          v-if="item.type === 'uploadImg'"
          v-model:file-list="item.fileList"
          :max-count="item.max || 1"
          list-type="picture-card"
          :before-upload="(file) => beforeUpload(file, item)"
          @remove="handleRemove(item)"
          @preview="handlePreview"
          @change="handleChange(item)"
        >
          <div v-if="item.fileList.length < (item.max || 1)">
            <plus-outlined />
          </div>
        </a-upload>
        <!-- 文件上传 -->
        <a-upload
          v-else-if="item.type === 'uploadFile'"
          v-model:file-list="item.fileList"
          :before-upload="(file) => beforeUpload(file, item)"
          @remove="handleRemove(item)"
          @change="handleChange(item)"
          class="flex"
        >
          <div class="flex justify-center items-center h-10 w-full border-[1px] border-slate-500 rounded-[9px]">
            <plus-outlined />
            上传文件
          </div>
        </a-upload>
        <!-- 文本输入框 -->
        <a-input
          v-else-if="item.type === 'input'"
          :placeholder="item.placeholder ? item.placeholder : `请输入${item.label}`"
          v-model:value="formState[item.name]"
          :disabled="item.disabled || false"
          v-trimInput
          autocomplete="off"
          class="h-10"
        />
        <!-- 搜索选择框 -->
        <a-select
          v-else-if="item.type === 'searchSelect'"
          :placeholder="item.placeholder ? item.placeholder : `请选择${item.label}`"
          :mode="item.mode"
          v-model:value="formState[item.name]"
          :options="item.options"
          show-search
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          :field-names="item.fieldNames"
          class="w-full h-10"
          size="large"
          @search="(value) => handleSearch(value, item)"
        ></a-select>
        <!-- 选择器 -->
        <a-select
          v-else-if="item.type === 'select'"
          :placeholder="item.placeholder ? item.placeholder : `请选择${item.label}`"
          v-model:value="formState[item.name]"
          :options="item.options"
          :field-names="item.fieldNames"
          class="h-10"
          size="large"
          :mode="item.mode"
        ></a-select>
        <!-- 单选框 -->
        <a-radio-group v-else-if="item.type === 'radio'" v-model:value="formState[item.name]" :button-style="item.buttonStyle">
          <a-radio-button v-for="v in item.options" :value="v.value" :key="v.value">{{ v.label }}</a-radio-button>
        </a-radio-group>
        <!-- 文本域 -->
        <a-textarea v-else-if="item.type === 'textarea'" v-model:value="formState[item.name]" :placeholder="item.placeholder ? item.placeholder : `请输入${item.label}`" :rows="3" />
      </a-form-item>
      <a-divider></a-divider>
    </a-form>
    <a-modal :open="previewVisible" :footer="null" @cancel="previewVisible = false" :z-index="500">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  formState: {
    type: Object,
    default() {
      return {};
    },
  },
  formItemList: {
    type: Array as any,
    default() {
      return [];
    },
  },
  rules: {
    type: Object,
    default() {
      return {};
    },
  },
  layout: {
    type: Object,
    default() {
      return {
        labelCol: { span: 24 },
        wrapperCol: { span: 14 },
      };
    },
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
});
const { getBase64 } = utils;
const { uploadFile, deleteFile } = api;
const formRef = ref();
const previewVisible = ref(false);
const previewImage = ref('');

onMounted(() => {
  props.formItemList.forEach(async (el) => {
    if ((el.type === 'searchSelect' || el.type === 'select') && el.actions) {
      el.options = await el.actions();
    }
    if (el.type === 'uploadImg') {
      el.fileList = props.isEdit
        ? [
            {
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: props.formState[el.name],
            },
          ]
        : [];
    }
  });
});

const handlePreview = async (file) => {
  if (!file.url && !file.preview) {
    file.preview = (await getBase64(file.originFileObj)) as string;
  }
  previewImage.value = file.url || file.preview;
  previewVisible.value = true;
};

const beforeUpload = (file: any, item) => {
  item.fileList = [...(item.fileList || []), file];
  props.formState[item.name] = '-1';
  return false;
};

const handleRemove = async (item) => {
  try {
    await deleteFile({ url: props.formState[item.name] });
    props.formState[item.name] = '';
    item.fileList = [];
  } catch (error) {}
};

const handleChange = async (item) => {
  try {
    const formData = new FormData();
    formData.append('file', item.fileList[0].originFileObj);
    const data = await uploadFile(formData);
    props.formState[item.name] = data.data.fileUrl;
  } catch (error) {}
};

const handleSearch = async (val, item) => {
  item.options = await item.actions(val);
};

/**
 * 请求列表
 */
const validateForm = async () => {
  formRef.value
    .validateFields()
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const resetForm = () => {
  formRef.value.resetFields();
  props.formItemList.forEach(async (el) => {
    if (el.type === 'upload' || el.type === 'uploadFile') {
      el.fileList = [];
    }
  });
};

/**
 * 暴露方法
 */
defineExpose({ validateForm, resetForm });
</script>
<style lang="scss" scoped>
.ant-input-textarea-show-count::after {
  display: none;
}
.ant-upload-wrapper {
  flex-direction: column;
}
</style>
