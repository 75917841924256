/**
 * 项目看板路由配置
 */
export default {
  path: '/workstation',
  name: 'workstation',
  meta: { title: '翻译工作站' },
  component: () => import('@/views/layout.vue'),
  children: [
    {
      path: '/station/list',
      name: 'stationList',
      component: () => import('@/views/workstation/station/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '工作站列表',
            value: null,
          },
        ],
      },
    },
    {
      path: '/station/detail',
      name: 'stationDetail',
      component: () => import('@/views/workstation/station/detail.vue'),
      meta: {
        breadcrumb: [
          {
            label: '工作站详情',
            value: null,
          },
        ],
      },
    },
    {
      path: '/translationPlaylet/list',
      name: 'translationPlayletList',
      component: () => import('@/views/workstation/translationPlaylet/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '翻译短剧库',
            value: null,
          },
        ],
      },
    },
    {
      path: '/translationPlaylet/dramaList',
      name: 'translationPlayletDramaList',
      component: () => import('@/views/workstation/translationPlaylet/dramaList.vue'),
      meta: {
        breadcrumb: [
          {
            label: '管理剧集',
            value: null,
          },
        ],
      },
    },
    {
      path: '/timbre/list',
      name: 'timbreList',
      component: () => import('@/views/workstation/timbre/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '音色管理',
            value: null,
          },
        ],
      },
    },
    {
      path: '/dramaRole/list',
      name: 'dramaRoleList',
      component: () => import('@/views/workstation/dramaRole/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '短剧角色管理',
            value: null,
          },
        ],
      },
    },
  ],
};
