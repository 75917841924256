<template>
  <div class="overflow-hidden">
    <a-row type="flex" class="w-screen h-screen flex-row overflow-x-auto" style="flex-flow: row">
      <a-col flex="160px">
        <div class="w-full h-full bg-cover bg-left bg-no-repeat bg-[url('@/assets/image/leftMenu-bg.png')] flex flex-col items-center">
          <img class="size-[54px] mt-[30px]" src="@/assets/image/logo.png" />
          <div class="w-[120px] py-[13px] flex items-center cursor-pointer" :class="{ select: menuIndex === index }" v-for="(menu, index) in mainMenus" :key="menu.id" @click="onChangeMainMenu(index)">
            <img :src="menuIcon(menu.data.icon, menuIndex === index)" class="mr-2" />
            <span class="text-[14px]" :style="{ color: menuIndex === index ? '#fff' : '#000' }">{{ menu.data.authName }}</span>
          </div>
        </div>
      </a-col>
      <a-col flex="auto" style="width: calc(100% - 160px); margin-left: -15px">
        <div class="w-full h-full z-10 rounded-t-2xl bg-white flex flex-col">
          <div class="flex flex-row w-full h-[41px] border-b-[0.8px] pl-[120px]">
            <a-row class="flex flex-1">
              <a-col :span="20">
                <a-tabs :activeKey="activeKey" hide-add type="editable-card" @edit="onEdit" @tabClick="onTabChange">
                  <a-tab-pane v-for="pane in panes" :key="pane.key" :tab="pane.title" :closable="panes.length > 1"></a-tab-pane>
                </a-tabs>
              </a-col>
              <a-col :span="4" class="flex items-center justify-center">
                <a-popover placement="bottom">
                  <template #content>
                    <p class="pt-3 pb-4 text-xm cursor-pointer" @click="visible = true">修改密码</p>
                    <p class="pb-3 text-xm cursor-pointer" @click="onLogOut">退出登录</p>
                  </template>
                  <div class="text-xm font-normal">
                    <span class="mr-2">Admin</span>
                    <DownOutlined />
                  </div>
                </a-popover>
              </a-col>
            </a-row>
          </div>
          <a-row class="flex flex-1 flex-row overflow-hidden" style="flex-flow: row">
            <a-col flex="120px" class="flex flex-col items-center">
              <div
                class="mt-[17px] px-[11px] text-[13px] py-[8px] flex w-11/12 cursor-pointer"
                :class="{ subBg: subMenuIndex === idx }"
                v-for="(subMenu, idx) in subMenus"
                :key="subMenu.id"
                @click="onChangeSubMenu(idx, subMenu.data.path)"
              >
                <span :style="{ color: subMenuIndex === idx ? '#3978f7' : '#737f93' }">{{ subMenu.data.authName }}</span>
              </div>
              <a-divider type="vertical" style="background-color: #eee" class="h-full absolute right-0 m-0" />
            </a-col>
            <a-col flex="auto" class="flex box-border overflow-y-auto">
              <div class="w-full p-4 overflow-x-hidden">
                <router-view v-slot="{ Component }">
                  <component :is="Component" />
                </router-view>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
    <a-modal :open="visible" width="500px" destroyOnClose title="修改密码" :confirm-loading="loading" @cancel="onCancelModal" @ok="onSureUpdate">
      <CustomForm ref="FormRef" :form-item-list="formItemList" :form-state="formState" :rules="rules"></CustomForm>
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
const { authLogout, changePassword } = api;
const { successMessage, validatorPassword, errorMessage } = utils;
const formItemList = ref<any>([
  { label: '旧密码', name: 'oldPass', type: 'input' },
  { label: '新密码', name: 'pass', type: 'input' },
  { label: '确认新密码', name: 'checkPass', type: 'input', placeholder: '请确认新密码' },
]);
const loading = ref(false);
const router = useRouter();
const FormRef = ref();
let visible = ref(false);
//当前选中的主菜单序号
let menuIndex = ref(0);
//选中子菜单序号
let subMenuIndex = ref(0);
//主菜单
let mainMenus: Array<PermissionList> = reactive([]);
//悬浮Tab菜单栏
let panes: Array<PaneMenu> = reactive([]);
let activeKey = ref();
//Form 表单
const formState: FormState = reactive({
  oldPass: '',
  pass: '',
  checkPass: '',
});

//form表单校验
let validatePass = async () => {
  if (formState.pass === '') {
    return Promise.reject('请输入新密码！');
  } else if (!validatorPassword(formState.pass)) {
    return Promise.reject('同时包含英文字母、数字和符号');
  } else {
    return Promise.resolve();
  }
};
let validateConfirmPass = async () => {
  if (formState.checkPass === '') {
    return Promise.reject('请输入确认密码！');
  } else if (!validatorPassword(formState.checkPass)) {
    return Promise.reject('同时包含英文字母、数字和符号');
  } else if (formState.checkPass !== formState.pass) {
    return Promise.reject('两次密码输入不一致!');
  } else {
    return Promise.resolve();
  }
};

const rules: Query = ref({
  oldPass: [{ required: true, message: '请输入旧密码', trigger: 'change' }],
  pass: [{ required: true, validator: validatePass, trigger: 'change' }],
  checkPass: [{ required: true, validator: validateConfirmPass, trigger: 'change' }],
});

const subMenus = computed(() => {
  if (!mainMenus.length) return [];
  return mainMenus[menuIndex.value].childrenNodes;
});

const menuIcon = computed(() => (icon: string, select: boolean) => {
  if (icon === 'm-system') {
    return select ? new URL('@/assets/image/menu/m-system-select.png', import.meta.url).href : new URL('@/assets/image/menu/m-system.png', import.meta.url).href;
  } else if (icon === 'q-drama') {
    return select ? new URL('@/assets/image/menu/q-drama-select.png', import.meta.url).href : new URL('@/assets/image/menu/q-drama.png', import.meta.url).href;
  } else if (icon === 'w-station') {
    return select ? new URL('@/assets/image/menu/w-station-select.png', import.meta.url).href : new URL('@/assets/image/menu/w-station.png', import.meta.url).href;
  }
  return '';
});

//动态缓存
watch(
  activeKey,
  () => {
    if (activeKey.value) {
      sessionStorage.setItem('activeKey', activeKey.value);
    }
  },
  { immediate: true }
);

watch(
  panes,
  () => {
    if (panes.length) {
      sessionStorage.setItem('tabMenus', JSON.stringify(panes));
    }
  },
  { immediate: true }
);

onMounted(() => {
  doMenus();
  router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (from.path !== '/' && mainMenus.length && to.name !== 'login') {
      //优先判断to路径是否是第二级
      const mainIndex = mainMenus.findIndex((main: PermissionList) => main.childrenNodes.some((v: PermissionList) => v.data.path === to.path));
      const panesIndex = panes.findIndex((v: PaneMenu) => v.key === to.path);
      const fromIndex = panes.findIndex((v) => v.key === from.path);
      if (to?.meta?.breadcrumb && Array.isArray(to.meta.breadcrumb) && to.meta.breadcrumb.length > 0) {
        if (mainIndex > -1) {
          //如果to路径是第二级，则找到对应的子节点
          const childrenNodeIndex = mainMenus[mainIndex].childrenNodes.findIndex((v: PermissionList) => v.data.path === to.path);
          //如果panes中没有对应的子节点，则添加，否则更新
          panesIndex === -1
            ? panes.push({
                title: mainMenus[mainIndex].childrenNodes[childrenNodeIndex].data.authName,
                key: to.path,
                params: to.params,
                query: to.query,
                mainPath: mainMenus[mainIndex].data.path,
                subPath: mainMenus[mainIndex].childrenNodes[childrenNodeIndex].data.path,
              })
            : (panes[panesIndex] = {
                title: mainMenus[mainIndex].childrenNodes[childrenNodeIndex].data.authName,
                key: to.path,
                params: to.params,
                query: to.query,
                mainPath: mainMenus[mainIndex].data.path,
                subPath: mainMenus[mainIndex].childrenNodes[childrenNodeIndex].data.path,
              });
        } else if (fromIndex > -1) {
          //如果to路径不是第二级，则找到对应的父节点
          panesIndex === -1
            ? panes.push({
                title: to.meta.breadcrumb[0].label,
                key: to.path,
                params: to.params,
                query: to.query,
                mainPath: panes[fromIndex].mainPath,
                subPath: panes[fromIndex].subPath,
              })
            : (panes[panesIndex] = {
                title: to.meta.breadcrumb[0].label,
                key: to.path,
                params: to.params,
                query: to.query,
                mainPath: panes[fromIndex].mainPath,
                subPath: panes[fromIndex].subPath,
              });
        }
      }
      //设置当前激活的key
      activeKey.value = to.path;
    }
    next();
  });
});

//删除菜单
const onEdit = (targetKey: any) => {
  let index = panes.findIndex((v) => v.key === targetKey);
  let params: any = {};
  if (activeKey.value === targetKey) {
    if (index > 0) {
      activeKey.value = panes[index - 1].key;
      params = panes[index - 1].query;
    } else {
      activeKey.value = panes[index + 1].key;
      params = panes[index + 1].query;
    }
    mainMenus.map((main: PermissionList, index: number) => {
      if (main.childrenNodes.findIndex((v: PermissionList) => v.data.path === activeKey.value) !== -1) {
        menuIndex.value = index;
        subMenuIndex.value = mainMenus[index].childrenNodes.findIndex((v: PermissionList) => v.data.path === activeKey.value);
        sessionStorage.setItem('mainMenu', mainMenus[index].data.path);
        sessionStorage.setItem('subMenu', activeKey.value);
      }
    });
    router.replace({ path: activeKey.value, query: params || {} });
  }
  panes.splice(index, 1);
};

//切换浮动菜单
const onTabChange = (key: any) => {
  panes.forEach((pane: PaneMenu) => {
    // 遍历panes数组，找到与key相等的pane
    if (pane.key === key && pane.mainPath && pane.subPath) {
      activeKey.value = key;
      router.replace({ path: key, query: pane.query || {} });
      mainMenus.some((main: PermissionList, index: number) => {
        if (main.data.path === pane.mainPath) {
          const subPath = pane.subPath || '';
          menuIndex.value = index;
          subMenuIndex.value = main.childrenNodes.findIndex((v: PermissionList) => v.data.path === subPath);
          sessionStorage.setItem('mainMenu', mainMenus[index].data.path);
          sessionStorage.setItem('subMenu', subPath);
          return true; // 提前退出循环
        }
      });
    }
  });
};

//切换主菜单
const onChangeMainMenu = (key: number) => {
  menuIndex.value = key;
  subMenuIndex.value = 0;
  sessionStorage.setItem('mainMenu', mainMenus[key].data.path);
  //切换子菜单
  let path = mainMenus[key].childrenNodes[0].data.path;
  sessionStorage.setItem('subMenu', path);
  activeKey.value = path;
  router.replace({ path });
};

//切换子菜单
const onChangeSubMenu = (idx: number, path: string) => {
  subMenuIndex.value = idx;
  sessionStorage.setItem('subMenu', path);
  activeKey.value = path;
  router.replace({ path });
};

//登出
const onLogOut = async () => {
  try {
    await authLogout(sessionStorage.getItem('loginId'));
    sessionStorage.clear();
    router.replace({ name: 'login' });
  } catch (e) {}
};

const onSureUpdate = async () => {
  //校验输入密码
  loading.value = true;
  const isValidate = FormRef.value.validateForm();
  if (isValidate) {
    try {
      await changePassword(sessionStorage.getItem('loginId'), formState.oldPass, formState.checkPass);
      successMessage('密码修改成功');
      loading.value = false;
      visible.value = false;
      onLogOut();
    } catch (error) {
      errorMessage('密码修改失败');
      loading.value = false;
    }
  } else {
    loading.value = false;
  }
};

const onCancelModal = () => {
  FormRef.value.resetForm();
  visible.value = false;
};

//处理菜单
const doMenus = () => {
  const permissionMenus = sessionStorage.getItem('permissionMenus') as string;
  if (permissionMenus) {
    let menus = JSON.parse(permissionMenus);
    if (!menus.length) return;
    menus.sort((a: PermissionList, b: PermissionList) => a.data.sortNo - b.data.sortNo);
    mainMenus.push(...menus);

    //查询缓存最近保存的路由
    if (sessionStorage.getItem('mainMenu')) {
      menuIndex.value = mainMenus.findIndex((v: PermissionList) => v.data.path === sessionStorage.getItem('mainMenu'));
      subMenuIndex.value = mainMenus[menuIndex.value].childrenNodes.findIndex((v: PermissionList) => v.data.path === sessionStorage.getItem('subMenu'));
    }
    //保存初始化位置
    sessionStorage.setItem('mainMenu', mainMenus[menuIndex.value].data.path);
    sessionStorage.setItem('subMenu', mainMenus[menuIndex.value].childrenNodes[subMenuIndex.value].data.path);

    //初始化选中菜单
    if (sessionStorage.getItem('tabMenus')) {
      panes.push(...JSON.parse(sessionStorage.getItem('tabMenus') as string));
      if (sessionStorage.getItem('activeKey')) {
        activeKey.value = sessionStorage.getItem('activeKey');
      }
      // const data = panes.find((v) => v.key === activeKey.value);
      // router.replace({ path: activeKey.value, query: data?.query || {} });
    } else {
      activeKey.value = mainMenus[menuIndex.value].childrenNodes[subMenuIndex.value].data.path;
      panes.push({
        title: mainMenus[menuIndex.value].childrenNodes[subMenuIndex.value].data.authName,
        key: mainMenus[menuIndex.value].childrenNodes[subMenuIndex.value].data.path,
        params: {},
        query: {},
        mainPath: mainMenus[menuIndex.value].data.path,
        subPath: mainMenus[menuIndex.value].childrenNodes[subMenuIndex.value].data.path,
      });
    }
  }
};
</script>
<style>
.ant-tabs-nav {
  margin-bottom: 0px !important;
}
.ant-tabs-nav::before {
  border-bottom: unset !important;
}
.select {
  background: url('@/assets/image/menu-selectBox.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 120px;
  height: 76px;
  padding-left: 10px;
  margin-left: -10px;
}
.subBg {
  background: #f2f7ff;
  border-radius: 6px;
}
</style>
