/**
 * 项目看板路由配置
 */
export default {
  path: '/quickDrama',
  name: 'quickDrama',
  meta: { title: 'QuickDrama' },
  component: () => import('@/views/layout.vue'),
  children: [
    {
      path: '/playlet/list',
      name: 'playletList',
      component: () => import('@/views/quickDrama/playlet/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '短剧管理',
            value: null,
          },
        ],
      },
    },
    {
      path: '/playlet/dramaList',
      name: 'dramaList',
      component: () => import('@/views/quickDrama/playlet/dramaList.vue'),
      meta: {
        breadcrumb: [
          {
            label: '管理剧集',
            value: null,
          },
        ],
      },
    },
    {
      path: '/member/list',
      name: 'memberList',
      component: () => import('@/views/quickDrama/member/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '用户管理',
            value: null,
          },
        ],
      },
    },
    {
      path: '/popular/list',
      name: 'popularList',
      component: () => import('@/views/quickDrama/popular/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '热门推荐',
            value: null,
          },
        ],
      },
    },
    {
      path: '/column/list',
      name: 'columnList',
      component: () => import('@/views/quickDrama/column/list.vue'),
      meta: {
        breadcrumb: [
          {
            label: '栏目管理',
            value: null,
          },
        ],
      },
    },
  ],
};
